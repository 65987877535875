<div class="contenedor__item">
  <div class="contenedor__card">
    <lib-tb-card [custom]="cardData">
      <ng-template libTbTemplate="contentTop">
        <div class="content">
          <span class="content-icon">
            <app-plus-icon></app-plus-icon>
          </span>
        </div>
      </ng-template>
      <ng-template libTbTemplate="contentBottom">
        <div class="description">
          {{ cardData.description }}
        </div>
      </ng-template>
    </lib-tb-card>
  </div>
</div>
