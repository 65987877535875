// src/app/prime-ng/prime-ng.module.ts
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenubarModule } from 'primeng/menubar';
import { CarouselModule } from 'primeng/carousel';
@NgModule({
  imports: [CommonModule, MenubarModule, CarouselModule],
  exports: [MenubarModule, CarouselModule],
})
export class PrimeNgModule {}
