// footer.component.ts
import { Component } from '@angular/core';

@Component({
  selector: 'shared-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  apkUrl: string = '../../assets/instalador/APPasignacionServiciosVF.apk';
  images = [
    {
      webp: '../../../../assets/img/Webp/slider-1.webp',
      fallback: '../../../../assets/img/slider-1.jpg',
    },
    {
      webp: '../../../../assets/img/Webp/slider-2.webp',
      fallback: '../../../../assets/img/slider-2.jpg',
    },
  ];
}
