import { Component, OnInit } from '@angular/core';
import { BreadcrumbService } from '@app/services/breadcrumb.service';
import { BreadCrumb } from '@app/shared/interface/breadcrumb';
import { Observable } from 'rxjs';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent implements OnInit {
  breadcrumbs$: Observable<BreadCrumb[]> = new Observable<BreadCrumb[]>();
  showBreadcrumb: boolean = false;

  constructor(
    private readonly breadcrumbService: BreadcrumbService,
    private readonly router: Router,
  ) {}

  ngOnInit(): void {
    this.breadcrumbs$ = this.breadcrumbService.breadcrumbs$;

    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
      this.updateBreadcrumbVisibility();
    });

    this.updateBreadcrumbVisibility();
  }

  private updateBreadcrumbVisibility(): void {
    this.showBreadcrumb = !this.router.url.includes('/home');
  }
}
