<nav *ngIf="showBreadcrumb && (breadcrumbs$ | async)?.length">
  <ul class="breadcrumb">
    <li>
      <span class="breadcrumb_title">Usted está en:</span>
    </li>
    <li *ngFor="let breadcrumb of breadcrumbs$ | async; let isLast = last">
      <span class="breadcrumb-link">{{ breadcrumb.label }}</span>
      <span *ngIf="!isLast" class="breadcrumb-separator">-</span>
    </li>
  </ul>
</nav>
