<div class="menu-container">
  <ng-container *ngIf="!isMobile; else mobileMenu">
    <p-menubar class="menu-container_items" [model]="items">
      <ng-template pTemplate="start">
        <a [routerLink]="['/']">
          <picture>
            <source srcset="../../../../assets/img/Webp/logo.webp" type="image/webp" />
            <img
              src="../../../../assets/img/logo.png"
              width="240"
              height="74"
              class="logo"
              alt="Logo"
              loading="lazy"
            />
          </picture>
        </a>
      </ng-template>
      <ng-template pTemplate="end">
        <a
          class="ingresar-button_desk"
          href="https://servicios.asistenciabolivar.com/Autenticacion/login.page"
          width="70"
          align="center"
          target="_blank"
          rel="noopener"
        >
          <picture>
            <source srcset="../../../../assets/img/Webp/B-ingresar.webp" type="image/webp" />
            <img src="../../../../assets/img/B-ingresar.png" alt="Ingresar" loading="lazy" />
          </picture>
        </a>
      </ng-template>
    </p-menubar>
  </ng-container>
  <ng-template #mobileMenu>
    <p-menubar class="menu-container_items" [model]="items">
      <ng-template pTemplate="start">
        <a [routerLink]="['/']">
          <img
            src="../../../../assets/img/logo.png"
            width="130"
            height="74"
            class="logo"
            alt="Logo"
            loading="lazy"
          />
        </a>
      </ng-template>
      <ng-template pTemplate="end">
        <a
          class="ingresar-button"
          href="https://servicios.asistenciabolivar.com/Autenticacion/login.page"
          width="60"
          align="center"
          target="_blank"
          rel="noopener"
        >
          <img src="../../../../assets/img/B-ingresar.png" alt="Ingresar" loading="lazy" />
        </a>
      </ng-template>
    </p-menubar>
  </ng-template>
</div>
<app-breadcrumb></app-breadcrumb>
