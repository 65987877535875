import { Component, Input } from '@angular/core';
import { ILibTbCard } from 'tech-block-lib';

@Component({
  selector: 'app-dynamic-content',
  templateUrl: './dynamic-content.component.html',
  styleUrls: ['./dynamic-content.component.scss'],
})
export class DynamicContentComponent {
  @Input() cardData: ILibTbCard = {
    orientation: 'vertical',
    img: {
      defaultHeight: 300,
      defaultWidth: 300,
      src: '',
    },
    title: '',
    description: '',
    observation: '',
  };
}
