import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ILibTbAnswerState } from 'tech-block-lib';

@Component({
  selector: 'app-error404-page',
  templateUrl: './error404-page.component.html',
  styleUrls: ['./error404-page.component.scss'],
})
export class Error404PageComponent {
  constructor(private readonly route: Router) {}

  custom: ILibTbAnswerState = {
    number: '404',
    title: 'No encontramos esta página',
    description: 'Busca otros servicios, ¡ASISTENCIA BOLIVAR está llena de soluciones!',
    img: {
      src: '../../../assets/img/404.png',
      defaultWidth: 300,
      defaultHeight: 300,
      alt: 'Error 404',
    },
    class: 'class-answer-state',
    libTbButton: {
      label: 'Inicio',
      icon: 'fa-solid fa-arrow-left',
      libTbClick: () => {
        this.redirectToHome();
      },
    },
  };

  redirectToHome(): void {
    this.route.navigate(['/']);
  }
}
