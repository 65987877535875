import { Component } from '@angular/core';

@Component({
  selector: 'app-dynamic-page-personales-ybanca',
  templateUrl: './dynamic-page-personales-ybanca.component.html',
  styleUrls: ['./dynamic-page-personales-ybanca.component.scss'],
})
export class DynamicPagePersonalesYBancaComponent {
  imgPathJpg = '../../../../assets/img/';
  imagepathWebp = '../../../../assets/img/Webp/';
  contents = [
    {
      imageSrc: `${this.imagepathWebp}logo-peke-SB.webp`,
      fallbackSrc: `${this.imgPathJpg}logo-peke-SB.jpg`,
      route: 'seguros-bolivar',
      subtitle: 'Asistencias Seguros Bolívar',
    },
    {
      imageSrc: `${this.imagepathWebp}logo-peke-DA.webp`,
      fallbackSrc: `${this.imgPathJpg}logo-peke-DA.jpg`,
      route: 'davivienda',
      subtitle: 'Asistencias Davivienda',
    },
    {
      imageSrc: `${this.imagepathWebp}logo-peke-SI.webp`,
      fallbackSrc: `${this.imgPathJpg}logo-peke-SI.jpg`,
      route: 'sinergia',
      subtitle: 'Asistencias Sinergia',
    },
  ];
}
