import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {
  @Input() href: string = '';
  @Input() target: string = '_self';
  @Input() imgSrc: string = '';
  @Input() altText: string = '';
}
