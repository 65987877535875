<div class="container_header">
  <span> Menú / Personales y Banca: </span>
</div>
<div class="container_page">
  <nav class="menu">
    <ul>
      <li *ngFor="let item of contents">
        <a
          [routerLink]="['/asistencia-bolivar/productos-asistenciales/personales-banca', item.route]"
        >
          <picture>
            <source srcset="../../../../assets/img/Webp/flecha-menu-off.webp" type="image/webp" />
            <img
              src="../../../../assets/img/flecha-menu-off.jpg"
              class="flecha"
              alt="flecha"
              loading="lazy"
            />
          </picture>
          <img [src]="item.imageSrc" alt="información" loading="lazy" />
        </a>
        <span
          [routerLink]="['/asistencia-bolivar/productos-asistenciales/personales-banca', item.route]"
          class="subtitle_menu"
          >{{item.subtitle}}</span
        >
      </li>
    </ul>
  </nav>
</div>
