import { Component } from '@angular/core';

@Component({
  selector: 'app-dynamic-page',
  templateUrl: './dynamic-page.component.html',
  styleUrls: ['./dynamic-page.component.scss'],
})
export class DynamicPageComponent {
  imgPathJpg = '../../../../assets/img/';
  imagepathWebp = '../../../../assets/img/Webp/';
  contents = [
    {
      imageSrc: `${this.imagepathWebp}TIT-hogar-asistencia-bolivar.webp`,
      fallbackSrc: `${this.imgPathJpg}TIT-hogar-asistencia-bolivar.jpg`,

      route: 'hogar',
    },
    {
      imageSrc: `${this.imagepathWebp}TIT-empresas-asistencia-bolivar.webp`,
      fallbackSrc: `${this.imgPathJpg}TIT-empresas-asistencia-bolivar.jpg`,
      route: 'empresas',
    },
    {
      imageSrc: `${this.imagepathWebp}TIT-construccion-asistencia-bolivar.webp`,
      fallbackSrc: `${this.imgPathJpg}TIT-construccion-asistencia-bolivar.jpg`,
      route: 'constructores',
    },
  ];
}
