import { Component } from '@angular/core';

@Component({
  selector: 'app-dynamic-page-vehiculos',
  templateUrl: './dynamic-page-vehiculos.component.html',
  styleUrls: ['./dynamic-page-vehiculos.component.scss'],
})
export class DynamicPageVehiculosComponent {
  imgPathJpg = '../../../../assets/img/';
  imagepathWebp = '../../../../assets/img/Webp/';
  contents = [
    {
      imageSrc: `${this.imagepathWebp}vehiculos-livianos.webp`,
      fallbackSrc: `${this.imgPathJpg}vehiculos-livianos.jpg`,
      route: 'vehiculos-livianos',
    },
    {
      imageSrc: `${this.imagepathWebp}vehiculos-pesados.webp`,
      fallbackSrc: `${this.imgPathJpg}vehiculos-pesados.jpg`,
      route: 'vehiculos-pesados',
    },
    {
      imageSrc: `${this.imagepathWebp}taxis.webp`,
      fallbackSrc: `${this.imgPathJpg}taxis.jpg`,
      route: 'taxis',
    },
    {
      imageSrc: `${this.imagepathWebp}usuario-tarjeta-diners.webp`,
      fallbackSrc: `${this.imgPathJpg}usuario-tarjeta-diners.jpg`,
      route: 'diners',
    },
  ];
}
