<div class="container_dynamic">
  <div class="dynamic-content" [ngClass]="[contentClass, imageOrientation]">
    <img
      [src]="imageSrc"
      alt="Descripción concisa del contenido"
      class="dynamic-content-image"
      [ngClass]="imageClass"
      loading="lazy"
    />
    <div class="dynamic-content-text">
      <h2 [innerHTML]="heading">{{ heading }}</h2>
      <div class="dynamic-content-description">
        <app-plus-icon *ngIf="showPlusIcon"></app-plus-icon>
        <div [innerHTML]="content"></div>
      </div>
    </div>
  </div>
</div>
