import { Component, HostListener, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-dynamic-card',
  templateUrl: './dynamic-card.component.html',
  styleUrls: ['./dynamic-card.component.scss'],
})
export class DynamicCardComponent implements OnInit {
  @Input() imageSrc?: string;
  @Input() heading?: string;
  @Input() content?: string;
  @Input() subtitle?: string;
  @Input() contentClass?: string = '';
  @Input() imageClass: string = '';
  @Input() showPlusIcon: boolean = true;
  @Input() route: string = '';
  @Input() forceOrientation?: 'horizontal' | 'vertical';
  imageOrientation: 'horizontal' | 'vertical' = 'vertical';

  ngOnInit() {
    this.setImageOrientation(window.innerWidth);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.setImageOrientation(event.target.innerWidth);
  }

  setImageOrientation(width: number) {
    if (this.forceOrientation) {
      this.imageOrientation = this.forceOrientation;
    } else {
      this.imageOrientation = width >= 1025 ? 'horizontal' : 'vertical';
    }
  }
}
