import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Error404PageComponent } from './pages/error404-page/error404-page.component';
import { MenuComponent } from './components/menu/menu.component';
import { FooterComponent } from './components/footer/footer.component';
import { PrimeNgModule } from '../pime-ng/prime-ng.module';
import { TechBlockModule } from '@app/tech-block/tech-block.module';
import { ButtonComponent } from './components/button/button.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { DynamicContentComponent } from './components/dynamic-content/dynamic-content.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DynamicCardComponent } from './components/dynamic-card/dynamic-card.component';
import { PlusIconComponent } from './components/atoms/plus-icon/plus-icon.component';
import { DynamicPageComponent } from './components/dynamic-page/dynamic-page.component';
import { DynamicPageVehiculosComponent } from './components/dynamic-page-vehiculos/dynamic-page-vehiculos.component';
import { DynamicPagePersonalesYBancaComponent } from './components/dynamic-page-personales-ybanca/dynamic-page-personales-ybanca.component';

@NgModule({
  declarations: [
    Error404PageComponent,
    MenuComponent,
    FooterComponent,
    ButtonComponent,
    BreadcrumbComponent,
    DynamicContentComponent,
    DynamicCardComponent,
    PlusIconComponent,
    DynamicPageComponent,
    DynamicPageVehiculosComponent,
    DynamicPagePersonalesYBancaComponent,
  ],
  imports: [CommonModule, PrimeNgModule, TechBlockModule, FormsModule, ReactiveFormsModule],
  exports: [
    Error404PageComponent,
    MenuComponent,
    FooterComponent,
    TechBlockModule,
    PrimeNgModule,
    BreadcrumbComponent,
    DynamicContentComponent,
    DynamicCardComponent,
    PlusIconComponent,
    DynamicPageComponent,
    DynamicPageVehiculosComponent,
    DynamicPagePersonalesYBancaComponent,
    FormsModule,
    ReactiveFormsModule,
  ],
})
export class SharedModule {}
