/* eslint-disable prettier/prettier */
import { Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';


@Component({
  selector: 'shared-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {
  constructor(private readonly breakpointObserver: BreakpointObserver) {}


  items: MenuItem[] = [];
  isMobile: boolean = false;

  ngOnInit() {
    this.breakpointObserver.observe([Breakpoints.Handset])
    .subscribe(result => {
      this.isMobile = result.matches;
    });
    this.items = [
      {
        label: 'Asistencia Bolívar',
        items: [{
          label: 'Nuestro ADN',
          items: [
            { label: 'Propósito', routerLink: 'nuestro-adn/proposito' },
            { label: 'Promesa de Servicio', routerLink: 'nuestro-adn/promesa-servicio' },
          ],
        },
        { label: 'Aliados', routerLink: 'aliados' },
        { label: 'Nuestro Equipo',
          items:[
            {label: 'Proveedores', routerLink: 'nuestro-equipo/proveedores'},
            {label: 'Oficinas móviles', routerLink: 'nuestro-equipo/oficinas-moviles'},
            {label: 'Call center', routerLink: 'nuestro-equipo/call-center'},
          ]
         },
         { label: 'Atención eventos de gran magnitud', routerLink: 'atencion-eventos' },
        ],
      },
      {
        label: 'Productos Asistenciales',
        items: [
          { label: 'Hábitat', routerLink: 'productos-asistenciales/habitat'},
          { label: 'Vehículos', routerLink: 'productos-asistenciales/vehiculos' },
          { label: 'Personales y banca', routerLink: 'productos-asistenciales/personales-banca' },
        ],
      },
      {
        label: 'Comunícate con Asistencia Bolívar',
        items: [
          { label: 'Call Center', routerLink: 'comunicate-con-asistencia-bolivar/call-center' },
          { label: 'Cuéntanos tu experiencia', routerLink: 'comunicate-con-asistencia-bolivar/experiencia' },
        ],
      },
      {
        label: '¿Cómo ser Proveedor?',
        routerLink: 'como-ser-proveedor'
      },

    ];
  }
}
