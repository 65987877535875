import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  LibTbCarouselModule,
  LibTbCardModule,
  LibTbDropdownModule,
  LibTbButtonModule,
  LibTbDynamicFormModule,
  LibTbAlertModule,
  LibTbInputTextModule,
  LibTbAccordionModule,
  LibTbCheckboxModule,
  LibTbAnswerStateModule,
} from 'tech-block-lib';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    LibTbCarouselModule,
    LibTbCardModule,
    LibTbDropdownModule,
    LibTbButtonModule,
    LibTbDynamicFormModule,
    LibTbAccordionModule,
    LibTbAlertModule,
    LibTbDynamicFormModule,
    LibTbInputTextModule,
    LibTbCheckboxModule,
    LibTbAnswerStateModule,
  ],
  exports: [
    LibTbCarouselModule,
    LibTbCardModule,
    LibTbDropdownModule,
    LibTbButtonModule,
    LibTbDynamicFormModule,
    LibTbAccordionModule,
    LibTbAlertModule,
    LibTbDynamicFormModule,
    LibTbInputTextModule,
    LibTbCheckboxModule,
    LibTbAnswerStateModule,
  ],
})
export class TechBlockModule {}
