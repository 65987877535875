import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router';
import { BreadCrumb } from '@app/shared/interface/breadcrumb';
import { BehaviorSubject, filter } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BreadcrumbService {
  private readonly breadcrumbsSubject = new BehaviorSubject<BreadCrumb[]>([]);
  public breadcrumbs$ = this.breadcrumbsSubject.asObservable();

  constructor(private readonly router: Router) {
    this.loadBreadcrumbsFromLocalStorage();

    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
      const breadcrumbs = this.buildBreadcrumbs(this.router.routerState.snapshot.root);
      const uniqueBreadcrumbs = this.removeDuplicates(breadcrumbs);
      this.breadcrumbsSubject.next(uniqueBreadcrumbs);
      this.saveBreadcrumbsToLocalStorage(uniqueBreadcrumbs);
    });
  }

  private buildBreadcrumbs(
    route: ActivatedRouteSnapshot,
    url: string = '',
    breadcrumbs: BreadCrumb[] = [],
  ): BreadCrumb[] {
    const label = route.data['breadcrumb'];
    const path = route.url.map(segment => segment.path).join('/');
    const nextUrl = path ? `${url}/${path}` : url;

    const breadcrumb: BreadCrumb = {
      label: label,
      url: nextUrl,
    };

    const newBreadcrumbs = label ? [...breadcrumbs, breadcrumb] : breadcrumbs;

    if (route.firstChild) {
      return this.buildBreadcrumbs(route.firstChild, nextUrl, newBreadcrumbs);
    }
    return newBreadcrumbs;
  }

  private removeDuplicates(breadcrumbs: BreadCrumb[]): BreadCrumb[] {
    return breadcrumbs.filter(
      (breadcrumb, index, self) =>
        index === self.findIndex(b => b.label === breadcrumb.label && b.url === breadcrumb.url),
    );
  }

  private saveBreadcrumbsToLocalStorage(breadcrumbs: BreadCrumb[]): void {
    localStorage.setItem('breadcrumbs', JSON.stringify(breadcrumbs));
  }

  private loadBreadcrumbsFromLocalStorage(): void {
    const storedBreadcrumbs = localStorage.getItem('breadcrumbs');
    if (storedBreadcrumbs) {
      const breadcrumbs = JSON.parse(storedBreadcrumbs) as BreadCrumb[];
      this.breadcrumbsSubject.next(this.removeDuplicates(breadcrumbs));
    }
  }
}
