<p-footer>
  <div class="footer-container">
    <div class="footer-top">
      <div class="footer-content">
        <div class="footer-left"></div>
        <div class="footer-center">
          <div class="carousel-wrapper">
            <p-carousel
              class="img_carousel"
              [value]="images"
              [numVisible]="1"
              [numScroll]="1"
              [autoplayInterval]="8000"
              [showNavigators]="false"
              [showIndicators]="false"
            >
              <ng-template pTemplate="item" let-image>
                <picture>
                  <source [attr.srcset]="image.webp" type="image/webp" />
                  <img [src]="image.fallback" alt="Slide" class="carousel-image" />
                </picture>
              </ng-template>
            </p-carousel>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-bottom">
      <div class="footer-links">
        <a [routerLink]="['comunicate-con-asistencia-bolivar/experiencia']" target="_self"
          >Cuéntanos tu experiencia</a
        >
        <span class="pipe">|</span>
        <a [routerLink]="['mapa-del-sitio']" target="_self">Mapa del Sitio</a>
        <span class="pipe">|</span>
        <a [routerLink]="['ley-de-proteccion-de-datos']" target="_self">Política de Privacidad</a>
      </div>
      <div class="footer-rights">Todos los derechos reservados 2025</div>
    </div>
  </div>
</p-footer>
